<template>
        
        <v-dialog v-model="dialog" persistent max-width="330">
            
            
            <v-card>
        <v-card-title class="text-h5 text-center">
          Приєднатися до заявки?
        </v-card-title>
        <v-card-text class="text-center">Ви впевнені, що хочете приєднатися до заявки: <span class="primary--text">{{ item && item.address }}</span>?</v-card-text>
        <v-card-actions>
          <v-btn
            color="deep-orange darken-1"
            text
            @click="closeDialog()"
          >
            Ні
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary lighten-1"
            text
            @click="sendForm"
          >
            Так
          </v-btn>
        </v-card-actions>
      </v-card>
            
    </v-dialog>
</template>

<script>
    import axios from "axios"
    import { mapGetters } from "vuex"
	export default {
        name: 'job-modal-confirm-worker',
		data: () => ({
            loading:false,
            dialog: false,
            form_data: {},
            item: null
            
        }),
        computed: {
            ...mapGetters({
                user: "auth/user",
            }),
        },
        methods: {
            show(item){
                this.item = item
                this.form_data.order = item.id
                this.dialog = true;
            },
            closeDialog (){
                this.dialog = false
            },
            sendForm() {
                    this.loading = true;
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "PUT",
                        url: "/api/service/order_processing/",
                        data: {id: this.item.id},
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-job');
                        this.$router.app.$snack.show("Ви долучилися до заявки!");
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loading = false))
            },
        }
    }
</script>