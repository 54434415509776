<template>
  <v-container :style="{'max-width': '1700px'}" >
    <!--<JobNavbar ref="navbarJob" :user="user" v-on:tab-data="changeTabData" v-on:view-tab="viewTab"/>-->
    <TableNavbar ref="navbarTable" :user="user" v-on:tab-data="changeTabData" v-on:view-tab="viewTab" menu="jobMenu" :showFieldDate="showFieldDate"/>
    
    <transition name="page" mode="out-in">
      <keep-alive>
        <component :user="user" :tabData="tabData" :is="is_component" v-on:show-field-date="showFieldDate = !showFieldDate"></component>
      </keep-alive>
    </transition>

    
    
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import JobTable from '@/components/Job/JobTable.vue'
//import JobNavbar from '@/components/Job/JobNavbar.vue'
import TableNavbar from '@/components/Abonents/TableNavbar.vue'
import JobTabStatistic from '@/components/Job/JobTabStatistic.vue'


export default {
  name: 'Job',
  components: {
    JobTable,
    //JobNavbar,
    TableNavbar,
    JobTabStatistic
  },
  data: () => ({
    is_component: 'JobTable',
    tabData: {},
    showFieldDate: false
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    viewTab(newTab){
      //console.log(newTab)
      this.is_component = newTab
    },
    changeTabData(newTabData){
      this.tabData = newTabData
    }
  }
}
</script>



