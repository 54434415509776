<template>
        
        <v-dialog v-model="dialog" persistent :max-width="500">
            
            <v-card>
                <v-card-title class="">
                    <span class="headline ">Звіт </span>
                </v-card-title>

                <div class="text-center mb-3">
                    <v-chip label outlined color="primary lighten-1">
                        <v-icon left>
                            mdi-map-marker-outline
                        </v-icon>
                        {{form_data.address }}
                    </v-chip>         
                </div>

                
            <v-card-text>
             
                  
                        <v-form 
                        ref="form"
                        v-model="valid"
                        lazy-validation> 
                            <v-row> 
                                <v-col cols="12"  class="px-0">
                                    <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                                   

                                    <v-textarea 
                                        dense
                                        rows="3"
                                        outlined
                                        hide-details="auto"
                                        :label="field.title"
                                        v-model="form_data[field.name]"
                                        :name="field.name"
                                        :rules="field.rules && textRules || notRules"
                                        :type="field.type"
                                        :disabled="field.disabled"
                                    ></v-textarea>
                                    </v-col>
                                    
                                    
                                   
                                    
                                </v-col>
                                
                            </v-row>
                                
                                <!------------------------------------------------------->
                            
                        </v-form>
                    
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                
                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Звіт</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    import { mapGetters } from "vuex"

	export default {
        name: 'job-modal-add',
		data: () => ({
            loadingSubmit:false,
            abonent: null,
            dialog: false,
            valid: true,
            form_data: {},
            fields: [
                {title: "Звіт",    name: "order_done_text",     rules: true,  type: "textarea", disabled: false, value: "" },
            ],
            
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        components:{
            //
        },
        computed: {
            ...mapGetters({
                user: "auth/user"
            }),
        },
        watch:{
            //
        },
        mounted(){
            //

        },
        methods: {
            show(item){
                
                this.clearModal()
                this.form_data = JSON.parse(JSON.stringify(item))
                this.dialog = true;
                
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.form_data = {}
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                //console.log(this.tab)
            },
            clearModal(){
                //
            },
            sendForm(){
                if(this.$refs.form.validate()){
                    this.loadingSubmit = true
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "PUT",
                        url: `/api/service/done_service/${this.form_data.id}/`,
                        data: {"order_done_text": this.form_data.order_done_text || ''},
                    })
                    .then(() => {
                        this.$emit('update-job');
                        this.$router.app.$snack.show("Звіт виконано!");
                        this.closeDialog()

                    })
                    .catch(err => {
                        this.$router.app.$sheet.show("Помилка!", err);
                    })
                    .finally(() => { this.loadingSubmit = false })
                }
            }
        }
    }
</script>