<template>
    <div>
    <v-data-table
      v-model="selectedTableRows"
      :show-select="tabData && tabData.report || tabData && tabData.accept" 
      :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark table-padding' || 'td_background table-padding':''"
      :server-items-length="paginations.count"
      :items-per-page="getItemsPerPage()"
      :options.sync="options"
      :page.sync="page"
      :footer-props="footer"
      :headers="headers"
      :loading="loading"
      :items="jobs"
      no-data-text="Немає заявок для відображення"
      loading-text="Завантаження..."
      class="elevation-1 row-cursor"
      focusable
      :dense="danse_table_job || $vuetify.breakpoint.smAndDown"
      :disable-sort="$vuetify.breakpoint.smAndDown"
      
    >
      <template v-slot:top>

        <v-toolbar flat color="secondary lighten-1" dark>
          
          <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''" @click="$emit('show-field-date')"><h2 class="mr-6 accent--text">Заявки</h2></v-toolbar-title>
          
          <v-spacer />
          <template v-if="!$vuetify.breakpoint.mobile">
            <v-btn
              v-if="tabData && tabData.report && selectedTableRows.length > 0" 
              depressed
              class="primary--text"
              color="accent"
              :loading="loadingAcceptJob"
              @click="getRaportJob"
              >
              Звіт ({{ selectedTableRows.length }})
            </v-btn>
            <v-btn
              v-if="tabData && tabData.accept && selectedTableRows.length > 0"
              depressed
              class="primary--text"
              color="accent"
              :loading="loadingAcceptJob"
              @click="getAcceptJob"
              >
              Взяти ({{ selectedTableRows.length }})
            </v-btn>
          </template> 
         
          
          <template v-if="selectedTableRows.length == 0 && paginations.count > options.itemsPerPage">
            <v-btn :disabled="loading || options.page <= 1" icon @click="page -= 1">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <template v-if="paginations.count">
              <span v-if="!$vuetify.breakpoint.xs" class="text-body-2">{{ options.page }} / {{ lastPage }} ( {{ paginations.count }} )</span>
            </template>
            <v-btn :disabled="loading || paginations.count == paginations.end_index" icon @click="page += 1">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <v-spacer />

          <template v-if="user">
            <v-btn 
                title="Створити заявку"
                icon @click="$refs.addJob.show('create')"> 
                <v-icon>mdi-plus</v-icon>
              </v-btn>
          </template>
          
          <v-switch
            v-if="!$vuetify.breakpoint.smAndDown"
            class="ml-2 mr-1"
            v-model="danse_table_job"
            title="Зменшити таблицю"
            hide-details
          ></v-switch>
              
          
          <v-btn icon :loading="loading" @click="getJob()" title="Поновити данні">
            <v-icon>autorenew</v-icon>
          </v-btn>
          
          <v-btn  
            icon 
            title="Фільтри пошуку"
            :disabled="tabData.filters != 'filters_allJob'"
            @click="$refs.filtersJob.show()"
            >
            <v-badge
            :value="countFilters > 0"
            color="accent"
            text="primary"
            :content="countFilters"
            dark
          >
            <v-icon>mdi-filter</v-icon>
            </v-badge>
          </v-btn>
            
          
          
        </v-toolbar>
        
      </template>

      <template
        v-if="countFilters > 0"
        v-slot:header="{ props: { headers } }"
      >
        <thead>
          <tr>
            <th :colspan="headers.length">
              Фільтри: 
              <keep-alive>
                <FiltersChips :filtersComponents="filtersComponents" v-on:clear-filter="clearFilter" :not_open_filters="tabData.filters != 'filters_allJob'" :is_close="tabData.filters == 'filters_allJob'" v-on:open-filters="$refs.filtersJob.show()"/>
              </keep-alive>
            </th>
          </tr>
        </thead>
      </template>
      
      

    <!-- ---------------------------------------- Cols ----------------------------------------- -->

      <template #item.index="{ item }">
        <span>{{ jobs.indexOf(item) + paginations.start_index }}</span>
      </template>
      
      <template #item.date_due="{ item }">
        <span class="text-ex-space">{{  item.date_due | formatDate }}</span>
      </template>
      
      <template #item.order_service="{ item }">
        <span class="d-flex justify-center" >
          <v-icon v-if="item && item.order_service == 'Телебачення'" color="green darken-2" >mdi-television</v-icon> 
          <v-icon v-else color="light-blue darken-2" >mdi-web</v-icon> 
        </span>
      </template>
      
      <template #item.order_type="{ item }">
          {{ item.order_type }}
        <v-chip
          v-if="item.need_check" 
          x-small
          class="white--text"
          color="orange"
        >
        <v-icon left x-small>mdi-alert-circle</v-icon> Необроблена
        </v-chip>
      </template>
      
      <template #item.operator_group="{ item }">
        {{ item && item.user && item.user.operator_group.name }}
      </template>
      
      <template #item.address="{ item }">
        <div href="#" v-if="!item.user_out"  class="link_text " :class="$vuetify.theme.dark ? 'primary--text text--lighten-1' : ''" @click.prevent="openAbonentInfoModal(item.user && item.user.id)">{{ item.address }}</div>
        <template v-else>{{ item.address }}</template>
        <!--<router-link v-else class="link_text" :to="{ name: 'abonent_info', params: { id: item.user, item: item }}">{{ item.address }}</router-link>-->
      </template> 
      
      <template #item.order_text="{ item }"> 
        <span
        class="link-cursor"
          title="Переглянути коментарі"
          @click="$refs.addJobComment.show(item)"
        >
          <v-badge
              :content="item.order_comment_count"
              color="orange"
              :value="item.order_comment_count > 0"
              overlap
              :inline="item.order_comment_count > 0"
              style="line-height: 1.5;"
          >
              {{ item.order_text }}
          </v-badge>
        </span>
      </template>
      
      <template #item.order_status="{ item }">
        <v-chip
          small
          :title="(item.order_status != 'Виконана' && item.order_status != 'Знята') && 'Редагувати' || 'Переглянути'"
          class="white--text"
          :color="getStatusColor(item.order_status)"
          @click="onClickRow(item)"
        >{{ item.order_status }}</v-chip> 
        
      </template>
      

      <template #item.order_done_text="{ item }">
        
        <v-edit-dialog v-if="user.user_type == 2 && (tabData && tabData.report)"
          :return-value.sync="item.order_done_text"
          large
          cancel-text="Ні"
          nudge-bottom
          save-text="Так"
          @save="selectedTableRows.indexOf(item) == -1 && selectedTableRows.push(item)"
        >
          <span>{{ item.order_done_text }} <v-icon v-if="!item.order_done_text" @click="1+1">mdi-plus</v-icon></span>
          <template v-slot:input>
            <v-textarea
              v-model="item.order_done_text"
              autofocus
              label="Звіт"
            ></v-textarea>
          </template>
        </v-edit-dialog>

        <template v-else >
          <template v-if="$vuetify.breakpoint.mobile">
            {{ item.order_done_text }}
          </template>
          <template v-else>
            <v-tooltip v-if="item.order_done_text " bottom color="primary" max-width="250px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon color="grey">mdi-message-text-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ item.order_done_text }}</span>
            </v-tooltip>
          </template>
        </template>
      </template>
      <!--<template #item.delete="{ item }">
        <v-icon @click="item">mdi-delete</v-icon>
      </template>-->
      <template #item.user_recive="{ item }">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.user_recive }}
            </span>
          </template>
          <span>{{ item.date_recive }}</span>
        </v-tooltip>
      </template>
      
      <template #item.user_worker="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-badge
                :content="'+' + (item.order_worker.length - 1)"
                :value="item.order_worker.length > 1"
                color="grey"
                overlap
                :inline="item.order_worker.length > 1"
                style="line-height: 1.5;"
              >
              {{ item.user_worker && item.user_worker.first_name }} {{ item.user_worker && item.user_worker.last_name }} {{ item.order_status == 'Знята' && !item.user_worker && ' ' || '' }}
              
              </v-badge>
              </span>
            </template>
            <span><span v-if="item.order_worker.length != 0">{{ showOrderWorker(item.order_worker) }}<br></span><span v-if="item.date_done">Звіт: {{ item.date_done }}</span></span>
          </v-tooltip>
      </template>

      
      <template #item.order_settings="{ item }">
        <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn  v-bind="attrs" v-on="on"
          @click="1+1" :color="$vuetify.breakpoint.smAndDown ? 'primary lighten-1' : ''" :icon="!$vuetify.breakpoint.smAndDown" small> 
          <span v-if="$vuetify.breakpoint.smAndDown">Опції</span>
          <v-icon v-else>mdi-dots-vertical</v-icon>
        </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-if="user && user.user_type == 2 && !tabData.report && tabData.section != 'group'" @click="$refs.jobConfirmWorker.show(item)">
            <v-icon class="pr-3">mdi-account-plus</v-icon>
            <v-list-item-title>Приеднатися</v-list-item-title> 
          </v-list-item>
          <v-list-item v-if="user && user.user_type == 2 && tabData.report" @click="$refs.jobModalSimpleReport.show(item)">
            <v-icon class="pr-3">mdi-check</v-icon>
            <v-list-item-title>Звіт</v-list-item-title> 
          </v-list-item>
          <v-list-item  v-if="user && user.user_type != 2" @click="onClickRow(item)">
            <template v-if="item.order_status != 'Знята' && item.order_status != 'Виконана'">
              <v-icon class="pr-3">mdi-pencil</v-icon>
              <v-list-item-title >Редагувати / Зняти</v-list-item-title>
            </template>
            <template v-else>
              <v-icon class="pr-3">mdi-history</v-icon>
              <v-list-item-title>Історія заявки</v-list-item-title>
            </template>
            
          </v-list-item>
          <v-list-item  @click="$refs.addJobComment.show(item)">
            <v-icon class="pr-3">mdi-comment-text-outline</v-icon>
            <v-list-item-title>Коментарі</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="user && user.user_type != 2" @click="$refs.addJobTag.show(item)"> 
            <v-icon class="pr-3">mdi-tag</v-icon>
            <v-list-item-title>Додати тег</v-list-item-title> 
          </v-list-item>
          <v-list-item  @click="$refs.addJobCommentSet.show(item)"> 
            <v-icon class="pr-3">mdi-comment-plus-outline</v-icon>
            <v-list-item-title>Додати коментар до заявки</v-list-item-title>
          </v-list-item>
          <v-list-item  @click="$refs.addCommentHouseSet.show(item)">
            <v-icon class="pr-3">mdi-comment-plus-outline</v-icon>
            <v-list-item-title>Додати коментар до будинку</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="item.need_check"  @click="$refs.jobConfirmCheckJob.show(item)">
            <v-icon class="pr-3">mdi-check-all</v-icon>
            <v-list-item-title>Перевірено</v-list-item-title>
          </v-list-item>
          <v-list-item target="_blank"  :href="`https://t.me/share/url?url=${encodeURIComponent(`${item.user && item.user.operator_group.name}`)}&text=${encodeURIComponent(`\n${'**' + item.address + '**'} \n${item.order_text}${!item.user_out && '\ncrmtv.altair.kr.ua/ab/' + item.user.id || ''}`)}`">
            <v-icon class="pr-3">mdi-share-variant</v-icon>
            <v-list-item-title>Поділитись</v-list-item-title>
          </v-list-item>

        </v-list>
      </v-menu>
        
      </template> 

      <template #item.tags="{ item }">
        <div @click="$refs.addJobTag.show(item)">
        <template v-for="tag in item.tags">
          <v-icon :key="tag.id" :title="tag.name" :color="tag.color" class="mr-1">
            mdi-tag
          </v-icon>
        </template>
      </div>
      </template>


    <!-- ---------------------------------------- /Cols ----------------------------------------- -->
    
    <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
      {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
    </template>
    
    </v-data-table>
    <v-app-bar
      v-if="$vuetify.breakpoint.mobile && selectedTableRows.length > 0"
      color="secondary"
      elevation="0"
      fixed
      dark
    >
      <v-btn text @click="selectedTableRows = []">
        Скинути
      </v-btn>
      <v-spacer />
        Вибрано {{ selectedTableRows.length }}
      <v-spacer />
      <v-btn
        v-if="tabData && tabData.report && selectedTableRows.length > 0" 
        depressed
        class="primary--text"
        color="accent"
        :loading="loadingAcceptJob"
        @click="getRaportJob"
        >
        Звіт
      </v-btn>
      <v-btn
        v-if="tabData && tabData.accept && selectedTableRows.length > 0"
        depressed
        class="primary--text"
        color="accent"
        :loading="loadingAcceptJob"
        @click="getAcceptJob"
        >
        Взяти
      </v-btn>
    </v-app-bar>


    <keep-alive>
      <JobModalAdd ref="addJob" v-on:update-job="updateTable += 1"/>
    </keep-alive>  
    <keep-alive>
      <JobModalAddComment ref="addJobComment" :updateTable="updateTable"/> 
    </keep-alive>  
    <keep-alive>
      <JobModalAddCommentSet ref="addJobCommentSet" v-on:open-modal="openModalAddComment()" v-on:update-job="updateTable += 1"/>
    </keep-alive> 
    <keep-alive>
      <ModalAddCommentHouseSet ref="addCommentHouseSet" v-on:open-modal="openModalAddComment()" v-on:update-job="updateTable += 1"/>
    </keep-alive> 
    <keep-alive>
      <JobModalConfirmWorker ref="jobConfirmWorker" v-on:update-job="updateTable += 1"/>
    </keep-alive> 
    <keep-alive>
      <JobModalConfirmCheckJob ref="jobConfirmCheckJob" v-on:update-job="updateTable += 1"/>
    </keep-alive> 
    <keep-alive>
      <Filters ref="filtersJob" v-on:apply-filters="checkGetJob(), $parent.$refs.navbarTable.filter_date = ''" for="job" />
    </keep-alive>
    <keep-alive>
      <AbonentInfoModal ref="AbonentInfoModal" backTo="Заявки" v-on:update-data="updateTable += 1"/>
    </keep-alive>
    <keep-alive>
      <JobModalSimpleReport ref="jobModalSimpleReport" v-on:update-job="updateTable += 1"/>
    </keep-alive>
    <keep-alive>
      <ModalSetTagJob ref="addJobTag" v-on:update-job="updateTable += 1"/>
    </keep-alive>
    
    
    </div>
    
  
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"
import JobModalAdd from '@/components/Job/JobModalAdd.vue'
import JobModalAddComment from '@/components/Job/JobModalAddComment.vue'
import JobModalAddCommentSet from '@/components/Job/JobModalAddCommentSet.vue'
import ModalAddCommentHouseSet from '@/components/Job/ModalAddCommentHouseSet.vue'

import JobModalConfirmWorker from '@/components/Job/JobModalConfirmWorker.vue'
import JobModalConfirmCheckJob from '@/components/Job/JobModalConfirmCheckJob.vue'
import JobModalSimpleReport from '@/components/Job/JobModalSimpleReport.vue'

//import JobTableSimpleDetails from '@/components/Job/JobTableSimpleDetails.vue'
import AbonentInfoModal from '@/components/Job/AbonentInfoModal.vue'
import Filters from '@/components/Abonents/Filters.vue'
import FiltersChips from '@/components/Abonents/FiltersChips.vue'
import ModalSetTagJob from '@/components/Job/ModalSetTagJob.vue'


export default {
  name: 'job-table',
  components: {
    JobModalAdd,
    JobModalAddComment,
    Filters,
    FiltersChips,
    AbonentInfoModal,
    JobModalConfirmWorker,
    JobModalConfirmCheckJob,
    JobModalAddCommentSet,
    ModalAddCommentHouseSet,
    JobModalSimpleReport,
    ModalSetTagJob
    //JobTableSimpleDetails
  },
  props: ["user", "tabData", "dataForFilters"],
  data: () => ({
    onClickRowStop: false,
    filter_date: new Date().toDateInputValue(),
    updateTable: 0,
    expanded: [],
    selectedTableRows:[],
    danse_table_job: JSON.parse(localStorage.getItem("danse_table_job")) || false,
    jobs: [],
    job_test:[
      {id: "1", date_process: "2021-02-06", order_type: "Ремонт", service: "Інтернет", operator: "Кривбастелеком", address: "ул. (Сечеславская / Кремлевская) 15 0", operator_memo: "Стоматология. зависает интернет, тупит", dispetcher: "Эдита Посыпайко", monter: "", status: "Перенесена", report: ""}, 
      {id: "2", date_process: "2021-02-06", order_type: "Підключення", service: "Інтернет", operator: "Кривбастелеком", address: "ул. (Сечеславская / Кремлевская) 11 40", operator_memo: "Повторное подключение на Стандартный тариф (пред.за 1 час. подъедет.)", dispetcher: "Эдита Посыпайко	", monter: "Віталя", status: "Виконана", report: "Все зробив як треба"}, 
      {id: "3", date_process: "2021-02-06", order_type: "Відключення", service: "Телебачення", operator: "Кривбастелеком", address: "ул. (Эдуарда Фукса / Тухачевского) 44 12", operator_memo: "Отключить от пакета УПП (не хотят польз.)", dispetcher: "Эдита Посыпайко	", monter: "", status: "Принята", report: ""}, 
    ],
    statusColor: {
      Прийнята:	"accent",
      Виконана:	"success",
      Знята:	"grey",
      Виконується: "primary lighten-1"
    },
    paginations: {}, 
    loading: false,
    loadingAcceptJob: false,
    options: {},
    page: 1,
    countFilters: 0,
    headers: [],
    dafaultHeaders: [
      //{ text: '#', value: 'index', sortable: false,  width: 50},
      //{ text: '#', value: 'id', sortable: false,  width: 50},
      { text: 'Дата', value: 'date_due',},
      { text: 'Пос.', value: 'order_service', },
      { text: 'Тип', value: 'order_type', },
      { text: 'Оператор', value: 'operator_group', },
      { text: 'Адреса', value: 'address', },
      { text: 'Заявка', value: 'order_text', sortable: false,},
      //{ text: 'Коментар', value: 'order_comment',sortable: false,},
      { text: 'Статус', value: 'order_status',  },
      { text: 'Прийняв', value: 'user_recive', },
      { text: 'Виконавець', value: 'user_worker', },
      { text: 'Звіт', value: 'order_done_text', sortable: false, },
      { text: '', value: 'tags',  sortable: false,},
      { text: '', value: 'order_settings', sortable: false}, 
      //{ text: '', value: 'data-table-expand' }, 
    ],
    headers_monter: [
      //{ text: '#', value: 'index', sortable: false,  width: 50},
      { text: 'Пос.', value: 'order_service', },
      { text: 'Тип', value: 'order_type', },
      { text: 'Оператор', value: 'operator_group', },
      { text: 'Адреса', value: 'address', },
      { text: 'Заявка', value: 'order_text', },
      //{ text: 'Коментар', value: 'order_comment',sortable: false, },
      { text: 'Статус', value: 'order_status',  },
      { text: 'Звіт', value: 'order_done_text', sortable: false, },
      //{ text: '', value: 'data-table-expand' }, 
      { text: '', value: 'tags',  sortable: false,},
      { text: '', value: 'order_settings', sortable: false}, 
    ],
    headers_monter_accept: [
      { text: 'Дата', value: 'date_due', },
      { text: 'Пос.', value: 'order_service', },
      { text: 'Тип', value: 'order_type', },
      { text: 'Оператор', value: 'operator_group', },
      { text: 'Адреса', value: 'address', },
      { text: 'Заявка', value: 'order_text', sortable: false,},
      //{ text: 'Коментар', value: 'order_comment',sortable: false, },
      { text: 'Статус', value: 'order_status',  },
      { text: 'Прийняв', value: 'user_recive', },
      //{ text: 'Виконавець', value: 'user_worker', },
      { text: '', value: 'tags',  sortable: false,},
      { text: '', value: 'order_settings', sortable: false}, 
      //{ text: '', value: 'data-table-expand' }, 
    ],
    headers_monter_group: [
      { text: 'Дата', value: 'date_due',},
      { text: 'Пос.', value: 'order_service', },
      { text: 'Тип', value: 'order_type', },
      { text: 'Оператор', value: 'operator_group', },
      { text: 'Адреса', value: 'address', },
      { text: 'Заявка', value: 'order_text', sortable: false,},
      { text: 'Статус', value: 'order_status',  },
      { text: 'Прийняв', value: 'user_recive', },
      { text: 'Виконавець', value: 'user_worker', },
      //{ text: 'Звіт', value: 'order_done_text', sortable: false, },
      { text: '', value: 'tags',  sortable: false,},
      { text: '', value: 'order_settings', sortable: false}, 
    ],
    headers_monter_report: [
      { text: 'Дата', value: 'date_due', },
      { text: 'Пос.', value: 'order_service', },
      { text: 'Тип', value: 'order_type', },
      { text: 'Адреса', value: 'address', },
      { text: 'Заявка', value: 'order_text', sortable: false,},
      //{ text: 'Коментар', value: 'order_comment', sortable: false,},
      { text: 'Статус', value: 'order_status',  },
      { text: 'Прийняв', value: 'user_recive', },
      { text: 'Звіт', value: 'order_done_text', sortable: false, },
      { text: '', value: 'tags',  sortable: false,},
      { text: '', value: 'order_settings', sortable: false}, 
      //{ text: '', value: 'data-table-expand' }, 
    ],
    footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
    },
      
  }),
  computed: {
      ...mapGetters({
          //operators: "filters/operators",
          table_settings: "table_settings/table_settings"
      }),
      
      lastPage(){
        let rawLastPage = this.paginations.count / this.options.itemsPerPage
        return rawLastPage && Math.ceil(rawLastPage) || 1
      },
      filters_allJob: function () {
        if(this.user?.operator_id == 1 && this.user?.user_type != 2){
          return {
            start_date_due: new Date().toDateInputValue(),
            end_date_due: new Date().toDateInputValue(),
            operator_group: [1, 2, 6, 12, 16]
          }
        }else{
          return {
            start_date_due: new Date().toDateInputValue(),
            end_date_due: new Date().toDateInputValue(),
          }
        }
        
      },
      filters_newJob: function(){
        let filters = {
          order_status: 1,
          end_date_due: new Date().toDateInputValue(),
          need_check: "false",
        }
        if(this.user?.operator_id == 1 && this.user?.user_type != 2){
          filters.operator_group = [1, 2, 6, 12, 16]
        }else if (this.user?.operator_id == 1) {
          filters.operator_group = [this.$parent?.$refs?.navbarTable?.filterOperator] || null
        }
        return filters
      },
      filters_nowJob: function(){
        if(this.user?.operator_id == 1 && this.user?.user_type != 2){
          return {
            order_status: 4,
            operator_group: [1, 2, 6, 12, 16]
          }
        }else{
          return {
            order_status: 4
          }
        }
        
      },
      filters_preJob: function(){
        if(this.user?.operator_id == 1 && this.user?.user_type != 2){
          return {
            order_status: 1,
            need_check: "true",
            not_pon: true,
            operator_group: [1, 2, 6, 12, 16]
          }
        }
        else{
          return {
            order_status: 1,
            need_check: "true",
            not_pon: true,
          }
        }
      },
      filters_ponJob: function(){
        if(this.user?.operator_id == 1 && this.user?.user_type != 2){
          return {
            order_status: 1,
            order_type: 10,
            operator_group: [1, 2, 6, 12, 16]
          }
        }
        else{
          return {
            order_status: 1,
            order_type: 10
          }
        }
      },
      filters_monter_plus_worker: function(){
        let filters = {
          order_status: 4,
          end_date_due: new Date().toDateInputValue(),
          order_worker__worker: this.user && this.user.id,
        }
        if (this.user?.operator_id == 1) filters.operator_group = [this.$parent?.$refs?.navbarTable?.filterOperator] || null
        return filters
      },
      filters_monter_report: function(){
        return {
          order_status: 4,
          end_date_due: new Date().toDateInputValue(),
          user_worker: this.user && this.user.id,
        }
      }
  },
  watch: {
    tabData: {
      handler () {
        //console.log("watch tabData")
        this.jobs = []
        this.viewTableHeaders() // Отображаем заголовки таблици относительно текущей вкладки заявок
        this.clearFiltersSettings() // Очищаем предидущий шаблон фильтров для вкладок заявок
        this.filtersSettings() // Создаём шаблон фильтров для текущей вкладки заявок
        //this.checkGetJob()
      },
      deep: true,
    },
    options: {
      handler (val, oldVal) {
        //console.log(this)
        //console.log("oldVal.page:", oldVal.page, oldVal, "val.page: ", val.page, val )
        oldVal.page && this.getJob();

        /**------ Зберігаємо до локально сховища зміну відображення кількості елементів на сторінці  -------*/

        if(oldVal.itemsPerPage && oldVal.itemsPerPage != val.itemsPerPage){
          if(this.$vuetify.breakpoint.mobile){
            localStorage.setItem("setting-table-job-itemsPerPage-mobile", val.itemsPerPage) 
            this.$router.app.settingTableJob.itemsPerPage_mobile = val.itemsPerPage
          }else{
            localStorage.setItem("setting-table-job-itemsPerPage", val.itemsPerPage) 
            this.$router.app.settingTableJob.itemsPerPage = val.itemsPerPage
          }
        } 
        /**---------------------------------------------------------------------------------- */
      },
      deep: true,
    },
    updateTable(){
      this.updateTable && this.getJob();
    },
    '$parent.$refs.navbarTable.counterApplyDate'(){
      this.applyNavbarDate(true) 
    },
    '$parent.$refs.navbarTable.counterApplyOperator'(){ 
      this.applyNavbarOperator(true) 
    },
    '$parent.$refs.navbarTable.counterApplyOperatorPon'(){ 
      this.applyNavbarOperatorPon(true) 
    },
    danse_table_job(){
      //console.log("danse_table_job")
      this.changeDenseTable()
    }
  },
  mounted (){
    //this.applyNavbarDate(true) 
    this.$nextTick(function () {
      this.createHeadersTable()
    })
  },
  activated() {
    this.viewTableHeaders() // Ищем и отображаем текущую вкладку заявок
    //this.applyNavbarDate()
  },
  deactivated(){
    //
  },
  methods: {
    getItemsPerPage(){
      /** Функція попереднього встановлення кількості елементів в таблиці відносно локального сховища та типу приладу */

      if(this.$vuetify.breakpoint.mobile){ 
        return this.$router.app.settingTableJob.itemsPerPage_mobile && Number(this.$router.app.settingTableJob.itemsPerPage_mobile) || 25
      }else{
        return this.$router.app.settingTableJob.itemsPerPage && Number(this.$router.app.settingTableJob.itemsPerPage) || 10
      } 
    },
    showOrderWorker(order_workers){
      let workerNames = ''
      order_workers.forEach(x => {workerNames += ', ' + x.name })
      return workerNames.replace(', ', '')
    },
    onClickRow(item){
      if(this.onClickRowStop) {
        this.onClickRowStop = false;
        return
      }
      this.user && this.user.user_type != 2 && this.$refs.addJob.show('change', item) 
    },
    createHeadersTable(){
      let operatorIndex = this.headers.indexOf(this.headers.find(x => x.value == "operator_group")) 
      if(this.user?.operator_id == 1){ // Якщо "Altair"
        //
      }else{
        this.headers.splice(operatorIndex, 1)
      }
    },
    getStatusColor(status){
      return this.statusColor[status]
    },
    applyNavbarDate(request=false){
      this.$refs.filtersJob.filters.find(x => x.name == "start_date_due").value = this.$parent.$refs.navbarTable.filter_date
      this.$refs.filtersJob.filters.find(x => x.name == "end_date_due").value = this.$parent.$refs.navbarTable.filter_date
      request && this.checkGetJob()
    },
    applyNavbarOperator(request=false){
      this.$refs.filtersJob.filters.find(x => x.name == "operator_group").value = [this.$parent.$refs.navbarTable.filterOperator]
      request && this.checkGetJob()
    },
    applyNavbarOperatorPon(request=false){
      this.$refs.filtersJob.filters.find(x => x.name == "operator_group").value = [this.$parent.$refs.navbarTable.filterOperatorPon]
      request && this.checkGetJob()
    },
    applyFiltersForTab(){ 
      /* Применяем предварительные фильтры для вкладок Job и убираем возможность изменения этих фильтров */
      for (let filter in this[this.tabData.filters]) {
        this.$parent.$refs.navbarTable.filter_date = new Date().toDateInputValue()
        this.$refs.filtersJob.filters.find(x => x.name == filter).value = this[this.tabData.filters][filter]
        //this.$refs.filtersJob.filters.find(x => x.name == filter).disable = true // вимкнути вибір фільтру
      }
      this.checkGetJob()
      return true
    },
    viewTableHeaders(){
      /* Отображаем заголовки таблици относительно текущей вкладки заявок */
      this.headers = this[this.tabData?.headers || 'dafaultHeaders']
    },
    clearFiltersSettings(){
      this.$refs.filtersJob.filters.forEach(element => { element.value = "", element.disable = false }); 
      this.$refs.filtersJob.clearSearchStreet += 1
    },
    filtersSettings(){
      /* Создаём шаблон фильтров для текущей вкладки заявок */
      this.tabData && this.tabData.filters && this.applyFiltersForTab() || this.checkGetJob()
    },
    getRaportJob(){
      //console.log(this.selectedTableRows)
      let compliteRaport = 0
      let finallyRaport = 0
      //let errorRaport = 0
      this.loadingAcceptJob = true
      this.selectedTableRows.forEach(element => {
        if(!element.order_done_text){
          this.$router.app.$sheet.show("Помилка!", "Текст звіту не може бути пустим")
          finallyRaport +=1
        }else{
          //console.log("startRaport")
          axios({
              headers: { "Content-type": "application/json; charset=UTF-8" },
              method: "PUT",
              url: `/api/service/done_service/${element.id}/`,
              data: {"order_done_text": element.order_done_text || ''},
          })
          .then(() => {
              compliteRaport += 1  
              //console.log("compliteRaport:", compliteRaport)
          })
          .catch(err => {
              //reject(err)
              //errorRaport +=1
              //console.log(err);
              this.$router.app.$sheet.show("Помилка!", err);
              //console.log("errorRaport:", errorRaport)
          })
          .finally(() => {
            finallyRaport +=1
            //console.log("finallyRaport: ", finallyRaport)
            if(this.selectedTableRows.length > 0 && this.selectedTableRows.length == finallyRaport){
              if(this.selectedTableRows.length == compliteRaport)this.$router.app.$snack.show("Звіт виконано");
              else if(compliteRaport == 0) this.loadingAcceptJob = false
              else this.$router.app.$snack.show("Звіт виконано частково");
              this.loadingAcceptJob = false
              //console.log("finally Requests")
              compliteRaport != 0 && this.checkGetJob()
            }
          })
        }
      });
      this.loadingAcceptJob = false
        
    
    },
    getAcceptJob(){
      this.loadingAcceptJob = true
      let listJob = []
      this.selectedTableRows.forEach(element => {
        listJob.push({id: element.id})
      });
      axios({
          headers: { "Content-type": "application/json; charset=UTF-8" },
          method: "POST",
          url: `/api/service/order_processing/`,
          data: {"order_list": listJob},
      })
      .then(() => {
          this.$router.app.$snack.show("Заявки прийнято!");
          this.checkGetJob()
      })
      .catch(err => {
          //reject(err)
          //console.log(err);
          this.$router.app.$sheet.show("Помилка!", err);
      })
      .finally(() => (this.loadingAcceptJob = false))
    },
    checkGetJob(){
      if(this.options.page == 1){
        this.getJob();
      }else{
        this.page = 1;
      }
    },
    clearFilter(filter_name){
      this.$refs.filtersJob.filters.find(x => x.name == filter_name).value = ''
      this.$refs.filtersJob.apply()
    },
    getFiltersOfComponents(){
      return this.$refs.filtersJob.filters.filter(x => x.value && x.value != '') 
    },
    filterFormat() {
      this.countFilters = 0
      const temp = {};
      /** СОРТУВАННЯ */ 
        if(this.options.sortBy[0]){
        if(this.options.sortBy[0] == 'address'){
          if (this.options.sortDesc[0]) temp.ordering = "street__street,house,letter,flat"
          else temp.ordering = "-street__street,-house,-letter,-flat"
        }else if(this.options.sortBy[0] == 'operator_group'){
          temp.ordering = !this.options.sortDesc[0] && '-' + "user__operator_group" || "user__operator_group"
          temp.ordering = temp.ordering + ",street__street,house,letter,flat"
        }else {
          temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0]
          temp.ordering = temp.ordering + ",street__street,house,letter,flat"
        }
      }
      /**-------------------------------- */
      //temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
      temp.itemsPerPage = this.options.itemsPerPage;
      temp.page = this.options.page;
      
      //if(this.search) temp.search = this.search
      
      /* получение значений фильтрации и просчет колличества фильтров */
      this.filtersComponents = this.getFiltersOfComponents()
      this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
      this.countFilters = this.filtersComponents.length
      
      return temp; 
    },
    getJob(){
      this.selectedTableRows = []
      this.loading = true;
      axios.get(`/api/service/get_orders/`, {params: this.filterFormat()})
      .then( response =>{
        //console.log(response.data)
        this.jobs = response.data.results
        this.paginations = response.data.paginations
        this.$parent.$refs.navbarTable.badgeCount = this.paginations.count
      })
      .catch(() => {
        //console.log(error);
        //this.errored = true;
      })
      .finally(() => (this.loading = false));
    },
    changeDenseTable (){
      localStorage.setItem("danse_table_job", this.danse_table_job);
    },
    openAbonentInfoModal(paramsUrlId){
      this.onClickRowStop = true
      this.$refs.AbonentInfoModal.paramsUrlId = paramsUrlId
      this.$refs.AbonentInfoModal.dialog = true
    },
    openModalAddComment(modalName, item){
      console.log('openModal')
      this.$refs[modalName].show(item)
    }
  }
}
</script>
<style scoped>
.link_text{
  text-decoration: none;
  cursor: pointer;
  color: #1a569b
}
.link-cursor {
    cursor: pointer !important
  }
.text-ex-space{
  white-space: nowrap !important /* Запрещаем перенос слов */
}
</style>